.ui.dropdown .divider.text {
  color: white;
  font-size: 14px;
  padding-left: 20px;
  padding-top: 10px;
  margin-bottom: 5px;
}

.nav-item.dropdown {
  .menu.transition.visible {
    width: 140px;
  }
  .dropdown.icon {
    color: white;
  }
}

.nav-item.dropdown.active {
  background: #ad0e14;
}
