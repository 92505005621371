@charset "UTF-8";
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}
html,
body {
  overflow-x: hidden;
}
.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden;
}

@media (min-width: 992px) {
  .login-page {
    display: flex;
    min-height: 100vh;
    flex-direction: row-reverse;
  }
}
.login-page .login-left {
  // background: url(/images/slice-login.jpeg) no-repeat;
  background-size: cover;
  position: relative;
  padding: 20px 15px 60px;
  color: white;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
}
@media (min-width: 992px) {
  .login-page .login-left {
    display: flex;
    align-items: center;
    width: 60%;
  }
}
@media (min-width: 1200px) {
  .login-page .login-left {
    font-size: 20px;
    line-height: 25px;
  }
}
.login-page .login-left .holder {
  width: 100%;
}
.login-page .login-left h2 {
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  margin: 0 0 8px;
}
@media (min-width: 992px) {
  .login-page .login-left h2 {
    margin: 0;
    min-height: 65px;
  }
}
@media (min-width: 1200px) {
  .login-page .login-left h2 {
    font-size: 32px;
    line-height: 36px;
    min-height: 85px;
  }
}
.login-page .login-left p {
  margin: 0 0 20px;
}
.login-page .login-logo {
  max-width: 420px;
  margin: 0 auto 80px;
  display: none;
}
@media (min-width: 992px) {
  .login-page .login-logo {
    display: block;
  }
}
@media (min-width: 1200px) {
  .login-page .login-logo {
    max-width: 620px;
  }
}
.login-page .login-logo img {
  display: block;
  width: 100%;
  height: auto;
}
.login-page .form-logo {
  max-width: 300px;
  margin: 0 auto 20px;
  display: block;
}
@media (min-width: 768px) {
  .login-page .form-logo {
    max-width: 420px;
    margin: 0 auto 40px;
  }
}
@media (min-width: 992px) {
  .login-page .form-logo {
    display: none;
  }
}
.login-page .form-logo img {
  display: block;
  width: 100%;
  height: auto;
}
.login-page .login-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px 15px;
  background: #464747;
}
.login-page .footer-link {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 0;
}
.login-page .footer-link li {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px 0 0;
  font-size: 16px;
  line-height: 20px;
}
.login-page .footer-link li a {
  color: white;
}
.login-page .footer-link li a:hover {
  color: black;
  text-decoration: none;
}
.login-page .login-right {
  text-align: center;
}
@media (min-width: 992px) {
  .login-page .login-right {
    width: 40%;
    display: flex;
    align-items: center;
  }
}
.login-page .login-right .holder {
  padding: 20px;
  margin: 0 auto;
  max-width: 520px;
  width: 445px;
}
.login-page .login-right h2 {
  font-size: 25px;
  line-height: 25px;
  margin: 0 0 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.paymentInput {
  border: none;
  border-bottom: 1px solid #c7c7c7;
  background: #ffffff;
  padding: 5px;
}

@media (min-width: 768px) {
  .login-page .login-right h2 {
    font-size: 35px;
    line-height: 35px;
    margin: 0 0 50px;
  }
}
.login-page .login-right h1 {
  margin: 0;
  font-weight: bold;
  font-size: 1.5rem;
}
@media (min-width: 768px) {
  .login-page .login-right h1 {
    font-size: 2.5rem;
  }
}
.login-page .login-right p {
  margin: 0 0 10px;
}

.login-form {
  text-align: left;
}
.login-form .login-input {
  margin: 0 0 20px;
}
.login-form .login-input table tbody tr td {
  border: none;
  border-bottom: 1px solid #eceeef;
}
.login-form input {
  border: 1px solid #c5cfd6;
  height: 49px;
  border-radius: 6px;
  font-size: 20px;
  color: #292c2e;
  padding: 10px 20px;
  outline: none;
  box-shadow: none;
  width: 100%;
  margin: 0 0 5px;
}
.login-form .login-red {
  width: 100%;
}
.login-form .signup-link {
  display: block;
  text-align: center;
}

.text-list {
  padding: 0;
  margin: 0;
  list-style: none;
  letter-spacing: -0.32em;
}
@media (min-width: 768px) {
  .text-list {
    margin: 0 -15px;
  }
}
.text-list li {
  letter-spacing: normal;
}
@media (min-width: 768px) {
  .text-list li {
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    padding: 0 15px;
  }
}

#header .header-top {
  padding-top: 15px;
  padding-bottom: 15px;
}
#header .header-top:after {
  clear: both;
  display: block;
  content: "";
}
@media (max-width: 767px) {
  #header .header-top.container {
    max-width: 100% !important;
    width: 100%;
  }
}
#header .header-top .navbar-toggler {
  float: left;
  border: 1px solid #333;
  color: #333;
  padding: 5px 8px;
  margin: 0px 10px 0 0;
  display: block;
}
@media (min-width: 768px) {
  #header .header-top .navbar-toggler {
    display: none;
  }
}
#header .header-top .logout {
  float: right;
  font-size: 14px;
  line-height: 20px;
  color: black;
  margin: 14px 0 0;
}
@media (min-width: 768px) {
  #header .header-top .logout {
    margin: 20px 0 0;
    font-size: 16px;
  }
}
#header .header-top .logout:hover {
  color: #ec1c23;
}

#header .logo {
  float: left;
  width: 170px;
}
@media (min-width: 768px) {
  #header .logo {
    width: 250px;
  }
}
#header .logo img {
  display: block;
  width: 100%;
  height: auto;
}

#header .navbar {
  padding: 0;
  background: #ec1c23;
}
@media (max-width: 767px) {
  #header .navbar {
    border-top: 1px solid #ec1c23;
  }
}
@media (max-width: 767px) {
  #header .navbar .container {
    max-width: 100% !important;
    padding: 0 !important;
    width: 100%;
  }
}
#header .navbar .navbar-nav > li {
  margin: 0 1px 0 0;
}
#header .navbar .navbar-nav > li.active > a,
.navbar .navbar-nav > li.show > a {
  background: #ad0e14;
}
#header .navbar .navbar-nav > li > a {
  color: white;
  display: block;
  padding: 10px 15px;
}
#header .navbar .navbar-nav > li > a:hover {
  background: #ad0e14;
}

.profile-page h2 {
  font-size: 30px;
  line-height: 40px;
  margin: 0 0 20px;
  font-weight: bold;
}

.profile-block {
  margin: 0 auto 30px;
  border: 1px solid #fcefef;
  padding: 15px 49px 0 15px;
  position: relative;
}
.profile-block .edit-profile {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #ec1c23;
  background: none;
  text-align: center;
  line-height: 33px;
  border: 1px solid #ec1c23;
}
.profile-block .edit-profile:hover {
  background: #ec1c23;
  color: white;
}
.profile-block .edit {
  display: inline-block;
  vertical-align: top;
  margin: 0 0 15px;
  font-weight: bold;
  text-decoration: underline;
}
.profile-block .edit:hover {
  text-decoration: none;
}
.profile-block .profile-list {
  padding: 0 0 20px;
  margin: 0;
  list-style: none;
  overflow: hidden;
  letter-spacing: -0.32em;
  color: #333;
}
.profile-block .profile-list li {
  letter-spacing: normal;
  font-size: 16px;
  line-height: 22px;
}
@media (min-width: 768px) {
  .profile-block .profile-list li {
    display: inline-block;
    vertical-align: top;
    letter-spacing: normal;
    width: 50%;
  }
  .profile-block .profile-list li:nth-child(even) {
    padding-left: 10px;
  }
}
.profile-block .profile-list li strong {
  font-weight: 700;
}

.rig-code {
  font-size: 16px;
  line-height: 22px;
  color: #333;
  margin: 0 0 15px;
}
.rig-code strong {
  font-weight: bold;
  display: block;
}
@media (min-width: 768px) {
  .rig-code strong {
    padding: 0 0 0 20px;
    display: inline;
  }
}

.portal-list {
  padding: 0;
  margin: 0 -10px;
  list-style: none;
  letter-spacing: -0.32em;
}
.portal-list li {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px 10px;
  letter-spacing: normal;
  width: 50%;
}
.portal-list li a {
  display: flex;
  padding: 15px 20px;
  line-height: 25px;
  font-size: 18px;
  background: #fcefef;
  color: #333;
  text-align: center;
  align-content: center;
  align-items: center;
  min-height: 190px;
}
@media (min-width: 768px) {
  .portal-list li a {
    line-height: 30px;
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .portal-list li a {
    min-height: 250px;
  }
}
.portal-list li a:hover {
  background: #ec1c23;
  color: white;
}
.portal-list li a span {
  display: block;
  text-align: center;
  width: 100%;
}

.account-page h2 {
  font-size: 30px;
  line-height: 40px;
  margin: 0 0 20px;
  font-weight: bold;
}
.account-page .total {
  display: block;
  text-align: right;
  font-size: 18px;
  font-weight: bold;
}

.level-page h2 {
  font-size: 30px;
  line-height: 40px;
  margin: 0 0 20px;
  font-weight: bold;
}

.level-head {
  padding: 15px;
  border: 1px solid #fcefef;
  margin: 0 0 15px;
}
.level-head:after {
  clear: both;
  content: "";
  display: block;
}
.level-head .left-cols {
  margin: 0 0 15px;
}
@media (min-width: 992px) {
  .level-head .left-cols {
    width: 70%;
    float: left;
    margin: 0;
  }
}
.level-head .left-cols:after {
  clear: both;
  content: "";
  display: block;
}
.level-head .left-cols .col {
  font-size: 12px;
  line-height: 18px;
  padding: 0;
}
@media (min-width: 768px) {
  .level-head .left-cols .col {
    float: left;
    width: 33.33%;
    padding: 0 10px 0 0;
  }
}
.level-head .left-cols .col p {
  margin: 0;
}
.level-head .left-cols .col .tag {
  display: inline-block;
  vertical-align: top;
  border-radius: 25px;
  background: #ec1c23;
  color: white;
  padding: 5px 15px;
  margin: 0 0 5px;
}
.level-head .right-info {
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
}
@media (min-width: 992px) {
  .level-head .right-info {
    float: right;
    width: 29%;
  }
}
.level-head .right-info p {
  margin: 0 0 5px;
}
.level-head .login-red {
  width: auto;
  padding: 5px 17px;
  float: right;
  margin: 0;
  line-height: 30px;
  height: auto;
  font-size: 17px;
}

.lavel-heading-area {
  background: #fcefef;
  padding: 10px 15px;
  margin: 0 0 15px;
}
.lavel-heading-area:after {
  display: block;
  clear: both;
  content: "";
}
.lavel-heading-area h3 {
  float: left;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}
.lavel-heading-area .right {
  float: right;
  position: relative;
}
.lavel-heading-area .right label {
  position: absolute;
  right: 0;
  top: 0;
  font-weight: normal;
}
.lavel-heading-area .right input {
  width: 120px;
  opacity: 0;
}

.level-info-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.level-info-holder .date-start-info .title {
  display: block;
  font-size: 15px;
  line-height: 20px;
  margin: 0 0 10px;
}
.level-info-holder .date-start-info .show-date {
  display: block;
  font-size: 22px;
  line-height: 30px;
}

.level-info-list {
  margin: 10px 0 0;
  padding: 0 0 20px;
  letter-spacing: -0.32em;
  list-style: none;
  border-top: 1px solid #333;
}
.level-info-list li {
  letter-spacing: normal;
  text-align: center;
  border-top: 1px solid #333;
  padding: 10px;
}
.level-info-holder.half-donut-graph {
  display: flex;
  flex-direction: column;
}
// ---------------------------------- start Media Queries for Levels .level-info-holder -----------------------
@media (max-width: 600px) {
  .level-info-holder {
    flex-direction: column;
  }
  .date-start-info {
    border-top: 1px solid #333;
  }
  .level-info-holder .date-start-info .show-date {
    border-bottom: 1px solid #333;
    text-align: center;
  }
  // .level-info-holder.half-donut-graph {
  //   width: 50%;
  // }
}
@media (min-width: 600px) {
  .level-info-holder {
    flex-direction: column;
  }
  .date-start-info {
    border-top: 1px solid #333;
  }
  .level-info-holder .date-start-info .show-date {
    border-bottom: 1px solid #333;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .level-info-holder {
    flex-direction: column;
  }
  .level-info-holder .level-info-list {
    border-top: none;
    margin: 0 -5px;
    width: 100%;
  }
  .level-info-list li {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    border-left: 1px solid #333;
    border-top: none;
    padding: 12px 10px;
    width: 20%;
  }
  .level-info-holder .date-start-info {
    text-align: center;
    padding-bottom: 15px;
    border-top: none;
  }
}

@media (min-width: 992px) {
  .level-info-holder .date-start-info {
    float: right;
    padding-bottom: 15px;
  }
}
@media (min-width: 1024px) {
  .level-info-holder {
    flex-direction: row;
  }
  .level-info-holder.level-info-list {
    float: left;
  }
  .level-info-holder .date-start-info {
    width: 20%;
  }
  .level-info-holder .date-start-info .show-date {
    border-bottom: none;
    font-size: 20px;
    line-height: 30px;
  }
  .level-info-holder .half-donut-graph {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}
@media (min-width: 1440px) {
  .level-info-holder {
    flex-direction: row;
  }
  .level-info-holder.level-info-list {
    float: left;
  }
  .level-info-holder .date-start-info {
    width: 20%;
  }
  .level-info-holder .date-start-info .show-date {
    border-bottom: none;
    font-size: 20px;
    line-height: 30px;
  }
  .level-info-holder .half-donut-graph {
    display: flex;
    flex-direction: column;
    width: 35%;
  }
}
.level-info-list li:first-child {
  border: none;
}
.level-info-list li span {
  display: block;
}
.level-info-list li span.title {
  font-size: 15px;
  line-height: 20px;
  margin: 0 0 10px;
}
.level-info-list li span.number {
  font-size: 20px;
  line-height: 25px;
}
.level-info-list li span.number sub {
  bottom: -0.2em;
}

.total-number {
  padding: 0 0 20px;
}
@media (min-width: 768px) {
  .total-number {
    float: right;
    width: 35%;
    padding: 60px 0 0;
  }
}
.total-number .tile {
  display: block;
  font-size: 20px;
  line-height: 20px;
  margin: 0 0 10px;
  opacity: 0.7;
}
.total-number .number {
  display: block;
  font-size: 35px;
  line-height: 40px;
}

.comment-form {
  padding: 15px 0 20px;
}
@media (min-width: 768px) {
  .comment-form {
    width: 60%;
    float: left;
  }
}
.comment-form textarea {
  width: 100%;
  height: 150px;
  border: 1px solid #fcefef;
  border-radius: 0;
  padding: 10px;
  margin: 0 0 20px;
}

.file-upload {
  position: static;
  width: 100%;
  border: 4px dashed #ccc;
  position: relative;
  text-align: center;
  padding: 60px 20px;
  color: #ccc;
}
.file-upload i {
  font-size: 50px;
  display: block;
  margin: 0 0 10px;
}
.file-upload p {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: Arial;
  margin: 0;
}
.file-upload input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  left: 0;
  top: 0;
}

.lavel-footer {
  overflow: hidden;
}

.level-nav {
  padding: 0;
  margin: 0;
  list-style: none;
  letter-spacing: -0.32em;
}
.level-nav > li {
  letter-spacing: normal;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 5px;
  display: inline-block;
  vertical-align: top;
  color: #333;
}
.level-nav > li:first-child:before {
  display: none;
}
.level-nav > li:before {
  content: ">";
  padding: 0 5px;
}
.level-nav > li.drop {
  position: relative;
}
.level-nav > li.drop > a {
  font-weight: bold;
  padding: 0 15px 0 0;
}
.level-nav > li.drop > a:after {
  position: absolute;
  right: 0;
  top: -2px;
  content: "";
  transform: rotate(180deg);
  font-family: "Font Awesome 5 Free";
}
.level-nav > li a {
  color: #333;
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.modal-dialog {
  max-width: 600px;
}
.modal-dialog .modal-content {
  padding: 15px;
  position: relative;
}
.modal-dialog .modal-content .close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.registration-code-modal {
  border-top: 5px solid #333;
}
.registration-code-modal h2 {
  text-align: center;
  font-size: 20px;
  margin: 0 0 15px;
}
.registration-code-modal .add-block {
  margin: 0 0 20px;
}
.registration-code-modal .add-block .head {
  overflow: hidden;
  margin: 0 0 10px;
}
.registration-code-modal .add-block .head .title {
  float: left;
  font-size: 18px;
  line-height: 22px;
}
.registration-code-modal .add-block .head .link {
  float: right;
  font-size: 14px;
  line-height: 22px;
  color: #333;
  text-decoration: underline;
}
.registration-code-modal .add-block .head .link:hover {
  text-decoration: none;
}
.registration-code-modal .add-table {
  width: 100%;
  border: 1px solid #fcefef;
  font-size: 14px;
}
.registration-code-modal .add-table.center td {
  text-align: center;
}
.registration-code-modal .add-table th {
  font-weight: normal;
  text-align: center;
  border-bottom: 1px solid #fcefef;
  padding: 10px;
}
.registration-code-modal .add-table tr {
  border-top: 1px solid #fcefef;
}
.registration-code-modal .add-table tr:first-child {
  border: none;
}
.registration-code-modal .add-table td {
  padding: 10px;
  border-left: 1px solid #fcefef;
}
.registration-code-modal .add-table td:first-child {
  border: none;
}
.registration-code-modal .add-table td .tag {
  background: #ec1c23;
  padding: 5px;
  display: block;
  color: white;
  border-radius: 6px;
}
.registration-code-modal .btn-holder {
  overflow: hidden;
}
.registration-code-modal .btn-holder .login-red {
  width: 100px;
  float: right;
}
.registration-code-modal .btn-holder .login-red.add {
  width: auto;
  float: none;
  margin: 0 auto;
  display: block;
}

.address-form {
  text-align: left;
}
.address-form .input-holder {
  margin: 0 0 10px;
}
.address-form input {
  border: 1px solid #c5cfd6;
  height: 39px;
  border-radius: 6px;
  font-size: 16px;
  color: #292c2e;
  padding: 5px 20px;
  outline: none;
  box-shadow: none;
  width: 100%;
  margin: 0 0 5px;
}

.tools-head {
  overflow: hidden;
  margin: 0 0 10px;
}
.tools-head .login-red {
  float: right;
  margin: 0 0 0 15px;
  width: auto;
}

.profile-info {
  overflow: hidden;
  margin: 0 0 15px;
}
.profile-info .img-holder {
  float: left;
  width: 50px;
  margin: 0 15px 0 0;
}
.profile-info .img-holder img {
  width: 100%;
  height: 50px;
  object-fit: cover;
  display: block;
  border-radius: 50%;
}
.profile-info .info {
  font-size: 14px;
  overflow: hidden;
  color: #333;
  line-height: 20px;
}
.profile-info .info .create {
  display: block;
}
.profile-info .detail-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.profile-info .detail-list li {
  display: inline-block;
  vertical-align: top;
}
.profile-info .detail-list li:first-child:before {
  display: none;
}
.profile-info .detail-list li:before {
  content: ".";
  font-size: 20px;
  padding: 0 5px;
  display: inline-block;
  vertical-align: top;
}

.tool-page h2 {
  font-size: 30px;
  line-height: 40px;
  margin: 0 0 5px;
  font-weight: normal;
}

#wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

#main {
  padding-top: 20px;
  padding-bottom: 20px;
}

a {
  transition: all 0.2s ease-in-out;
}

.login-red {
  width: 100%;
  height: 49px;
  border: 1px solid #ec1c23;
  border-radius: 6px;
  background: none;
  color: #ec1c23;
  font-size: 22px;
  line-height: 49px;
  margin: 0 0 10px;
  padding: 0 15px;
  cursor: pointer;
  text-align: center;
}
.login-blue {
  width: 100%;
  height: 49px;
  border: 1px solid #3b7bbf;
  border-radius: 6px;
  background: none;
  color: #3b7bbf;
  font-size: 22px;
  line-height: 49px;
  margin: 0 0 0px;
  padding: 0 15px;
  cursor: pointer;
  text-align: center;
}
.login-red:hover {
  background: #ec1c23;
  color: white;
}
.login-blue:hover {
  background: #3b7bbf;
  color: white;
}
.login-red.small {
  height: auto;
  padding: 5px 15px;
  font-size: 16px;
  line-height: 25px;
}

.dropdown-item:hover {
  background: #636363;
  color: white;
}

.table-head {
  overflow: hidden;
  margin: 10px;
}
.table-head .login-red {
  float: right;
  width: auto;
}
.table-head h3 {
  float: left;
  font-size: 22px;
  margin: 26px 0 0;
}

.table-holder {
  overflow-x: auto;
  padding: 0 0 32px;
}
.table-holder.left-align table th {
  background: #e9e9e9;
}
.table-holder.left-align table th,
.table-holder.left-align table td {
  text-align: left;
}
.table-holder table {
  text-align: center;
  width: 100%;
  border: 1px solid #f7f6f6;
  font-size: 14px;
  min-width: 1150px;
}
.table-holder table th {
  padding: 10px;
  border-left: 1px solid #e9e9e9;
  text-align: center;
  background: #e9e9e9;
}
.table-holder table th:first-child {
  border: none;
}
.table-holder table th.w-250 {
  width: 250px;
}
.table-holder table tbody tr {
  border-top: 1px solid #f7f6f6;
}
.table-holder table tbody tr:nth-child(even) {
  background: #f7f6f6;
}
.table-holder table td {
  padding: 10px;
  border-left: 1px solid #f7f6f6;
  text-align: center;
}
.table-holder table td:first-child {
  border: none;
}
.table-holder table td input {
  width: 100px !important;
  margin: 3px 0;
}
.table-holder table td textarea {
  width: 100%;
  height: 100px;
}
.table-holder table .dropdown .dropdown-toggle:after {
  display: none;
}
.table-holder table .dropdown .dropdown-toggle i {
  font-size: 20px;
}
.table-holder table .dropdown .dropdown-menu {
  left: auto;
  right: 100%;
  top: -50%;
  margin: -34px 0 0;
}
.table-holder .btn-holder {
  overflow: hidden;
}
.table-holder .btn-holder .login-red {
  width: 100px;
  float: right;
}

.ui-datepicker .ui-datepicker-title {
  height: 26px;
}

.ui-datepicker .ui-datepicker-header {
  background: #fcefef;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  background: #fcefef;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background: #ec1c23;
  border-color: #ec1c23;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: #ec1c23;
  border-color: #ec1c23;
  color: white;
}
.assessment {
  padding: 20px 50px !important;
}
.ui.modal > .close {
  top: 1.0535rem;
  right: 1rem;
  color: #333 !important;
}

.error_input {
  color: red;
}
.dropdown-container {
  display: flex;
  align-items: center;
  .cotaniner-text {
    font-size: 25px;
    position: relative;
  }
}
.ui.large.modal {
  bottom: unset !important;
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  .content {
    border-top: 5px solid #333;
  }
}
.ui.mini.modal {
  bottom: unset !important;
  margin: 200px auto;
}
.ui.small.modal {
  bottom: unset !important;
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  .content {
    border-top: 5px solid #333;
  }
}
@media only screen and (min-width: 768px) {
  body ::-webkit-scrollbar {
    width: unset !important;
  }
}
.modal {
  overflow: auto !important;
}

.select-account {
  border: 1px solid #c5cfd6;
  height: 49px;
  border-radius: 6px;
  font-size: 20px;
  color: #292c2e;
  padding: 10px 20px;
  outline: none;
  box-shadow: none;
  width: 100%;
  margin: 0 0 5px;
}

.footer-link li {
  cursor: pointer;
}

.visible.menu.transition {
  padding-top: 10px;
}

.ui.styled.accordion {
  width: 100% !important;
}

.terms-service h3 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.jargon {
  color: blue;
  text-decoration: underline;
}
